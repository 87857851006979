/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

import Nav0 from './Nav0';
import Banner0 from './Banner0';
import Pricing0 from './Pricing0';
import Pricing0copy from './Pricing0copy';
import Teams4 from './Teams4';
import Footer2 from './Footer2';
import Footer0 from './Footer0';

import {
  // Nav00DataSource,
  Banner00DataSource,
  Pricing00DataSource,
  Pricing01DataSource,
  Nav02DataSource,
  Teams40DataSource,
  Footer20DataSource,
  Footer00DataSource,
} from './data.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location } = window;

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
    // this.componentPages()
  }
//   componentWillUpdate(){
//     document.getElementById('root').scrollIntoView(true);//为ture返回顶部，false为底部
// }

    // TODO:这种判断方法不可取，需要优化
  // componentPages = () => {
  //   if(window.location.href !== 'http://localhost:3000/home'){
  //     this.props.changePages(true)
  //   }
  //   // if(window.location.href === 'http://localhost:3000/host'){
  //   //   this.props.changePages(true)
  //   // }
  //   }
  render() {
    const children = [
      // <Nav0
      //   id="Nav0_0"
      //   key="Nav0_0"
      //   dataSource={Nav02DataSource}
      //   isMobile={this.state.isMobile}
      // />,
      <Nav0
      id="Nav0_2"
      key="Nav0_2"
      dataSource={Nav02DataSource}
      isMobile={this.state.isMobile}
    />,
      <Banner0
        id="Banner0_0"
        key="Banner0_0"
        dataSource={Banner00DataSource}
        isMobile={this.state.isMobile}
      />,
      <Pricing0
        id="Pricing0_0"
        key="Pricing0_0"
        dataSource={Pricing00DataSource}
        isMobile={this.state.isMobile}
      />,
      <Pricing0copy
        id="Pricing0_1"
        key="Pricing0_1"
        dataSource={Pricing01DataSource}
        isMobile={this.state.isMobile}
      />,
      <Teams4
        id="Teams4_0"
        key="Teams4_0"
        dataSource={Teams40DataSource}
        isMobile={this.state.isMobile}
      />,
      <Footer2
        id="Footer2_0"
        key="Footer2_0"
        dataSource={Footer20DataSource}
        isMobile={this.state.isMobile}
      />,
      <Footer0
        id="Footer0_0"
        key="Footer0_0"
        dataSource={Footer00DataSource}
        isMobile={this.state.isMobile}
      />,
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
