import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
// import { HashRouter as Router, Route } from "react-router-dom";
import { enquireScreen } from 'enquire-js';
// import Header from './layout/Nav0';
// import Footer from './layout/Footer0';
// import layout from './layout';
import Home from './Home';
import Host from './Host';

// import {
//   Nav00DataSource,
//   Footer00DataSource,
// } from './layout/data.source.js';
// import './layout/less/antMotionStyle.less';
// import './layout/less/edit.less';
let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
  }
//   componentWillUpdate(){
//     document.getElementById('root').scrollIntoView(true);//为ture返回顶部，false为底部
// }
  render() {
    return (
      <Router>
        <div>
          {/* <Header dataSource={Nav00DataSource} isMobile={this.state.isMobile} /> */}
          <Route exact path="/" component={Home} />
          <Route exact path="/host" component={Host} />
          {/* <Footer dataSource={Footer00DataSource} isMobile={this.state.isMobile} /> */}
        </div>
      </Router>
    );
  }
}

export default App;