import React from 'react';
// import Link from 'react-router-dom';
// import {Link} from 'antd';
// export const Nav00DataSource = {
//   wrapper: { className: 'header0 home-page-wrapper jxocvzr0pf-editor_css' },
//   page: { className: 'home-page jxoc9w9ljz7-editor_css' },
//   logo: {
//     className: 'header0-logo',
//     children: 'https://pic.hereamazing.com/webpic/images/logo.svg',
//   },
//   Menu: {
//     className: 'header0-menu',
//     children: [
//       {
//         name: 'item0',
//         a: {
//           children: (
//             <>
//               <p>导d</p>
//             </>
//           ),
//           href: '',
//           className: 'jxocwaxbre-editor_css',
//         },
//       },
//       {
//         name: 'item1',
//         a: {
//           children: (
//             <>
//               <h3>
//                 <u>导航二</u>
//               </h3>
//             </>
//           ),
//           href: '',
//           className: 'jxocw9m2ykr-editor_css',
//         },
//       },
//       { name: 'item2', a: { children: '导航三', href: '' } },
//       { name: 'item3', a: { children: '导航四', href: '' } },
//     ],
//   },
//   mobileMenu: { className: 'header0-mobile-menu' },
// };
export const Nav02DataSource = {
  wrapper: { className: 'header0 home-page-wrapper jxodlvy1x9m-editor_css' },
  page: { className: 'home-page jxpjhiofohi-editor_css' },
  logo: {
    className: 'header0-logo jxodljf52sb-editor_css',
    children: 'https://pic.hereamazing.com/webpic/images/logo.svg',
    href:'https://www.hereamazing.com',
  },
  Menu: {
    className: 'header0-menu jxpjefrz9io-editor_css',
    children: [
      {
        name: 'item~jxpx9q48shh',
        a: {
          children: (
            <>
              <p>首页</p>
            </>
          ),
          href: '/',
          className: 'jxoe840fy9d-editor_css',
        },
        className: 'jxpwqu34bal-editor_css',
      },
      {
        name: 'item2',
        a: {
          children: (
            <>
              <p>体验主</p>
            </>
          ),
          href: '/host',
          className: 'jxoe840fy9d-editor_css',
        },
        className: 'jxpwqu34bal-editor_css',
      },
      {
        name: 'item~jxpx65uqopq',
        // href: 'https://www.hereamazing.com/hostadmin/register',
        a: {
          children: (
            <>
              <p>体验主管理后台</p>
            </>
          ),
          href: '/hostadmin',
          className: 'jxoe840fy9d-editor_css',
        },
        className: 'jxpwqu34bal-editor_css',
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu jxpjfs251hh-editor_css' },
};
export const Banner00DataSource = {
  wrapper: { className: 'banner0 jxojc16h1z-editor_css' },
  textWrapper: { className: 'banner0-text-wrapper jxojdzhi8bn-editor_css' },
  title: {
    className: 'banner0-title jxojbfnhvy-editor_css',
    children: (
      <>
        <p>一个很酷的体验分享平台，让有趣和兴趣相连</p>
      </>
    ),
  },
  content: {
    className: 'banner0-content jxoje9sxbkp-editor_css',
    children: (
      <>
        <p>找到新奇好玩的体验，发现独特有趣的人类</p>
      </>
    ),
  },
  button: {
    className: 'banner0-button jxojes1ymrt-editor_css',
    children: (
      <>
        <p>微信小程序</p>
      </>
    ),
  },
};
export const Pricing00DataSource = {
  wrapper: { className: 'home-page-wrapper pricing0-wrapper' },
  OverPack: {
    playScale: 0.3,
    className: 'home-page pricing0 jxpkgua6sq-editor_css',
    always: true,
    appear: true,
  },
  imgWrapper: {
    className: 'pricing0-img-wrapper jxpkhhbixs-editor_css',
    md: 12,
    xs: 24,
  },
  img: {
    className: 'pricing0-img jxpjevgd316-editor_css',
    name: 'image',
    children: 'https://pic.hereamazing.com/webpic/images/host_tample.jpg',
  },
  childWrapper: {
    className: 'pricing0-text-wrapper jxpkpyk5ie9-editor_css',
    md: 12,
    xs: 24,
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>与他人分享你的热爱</p>
          </>
        ),
        className: 'pricing0-title jxpkgrktuy-editor_css',
      },
      {
        name: 'content',
        children: (
          <>
            <p>
            在鱼眼的世界里，永远想象不到有多少意料之外的惊喜在等你。这里聚集着有趣会玩的人，拒绝一切沉闷呆板的无聊。分享着各种生活的可能，也让每一种都散发着光亮。
            </p>
          </>
        ),
        className: 'pricing0-content jxpkht4ady-editor_css',
      },
      {
        name: 'pricing',
        children: '¥',
        className: 'pricing0-pricing jxpkgd4pbuj-editor_css',
      },
      {
        name: 'button',
        children: {
          href: '/host',
          type: 'default',
          children: (
            <>
              <p>成为体验主</p>
            </>
          ),
          className: 'jxpklhxf47k-editor_css',
          target: '',
        },
      },
    ],
  },
};
export const Pricing01DataSource = {
  wrapper: {
    className: 'home-page-wrapper pricing0-wrapper jxpsvbubbdf-editor_css',
  },
  OverPack: {
    playScale: 0.3,
    className: 'home-page pricing0 jxppynokgb9-editor_css',
  },
  imgWrapper: {
    className: 'pricing0-img-wrapper jxppyccntmm-editor_css',
    md: 12,
    xs: 24,
  },
  img: {
    className: 'pricing0-img jxpsvdxqrs-editor_css',
    name: 'image',
    children: 'https://pic.hereamazing.com/webpic/images/tiyanzhe.jpg',
  },
  childWrapper: {
    className: 'pricing0-text-wrapper jxppykjno6r-editor_css',
    md: 12,
    xs: 24,
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>找到兴趣相投的人</p>
          </>
        ),
        className: 'pricing0-title jxppzxj6rsh-editor_css',
      },
      {
        name: 'content',
        children:
          '每一个独一无二的你，都可以找到气味相似的人。在相同的频道，做着热爱的事情，听见彼此的故事，共赴生活的高潮。',
        className: 'pricing0-content jxpq0776zsq-editor_css',
      },
      {
        name: 'pricing',
        children: '¥',
        className: 'pricing0-pricing jxpq0fojbz-editor_css',
      },
      {
        name: 'button',
        children: {
          type: 'default',
          children: '小程序查看',
          className: 'jxpxdwnconu-editor_css',
        },
        className: 'jxpxnj6z9id-editor_css',
      },
    ],
  },
};
export const Teams40DataSource = {
  wrapper: {
    className: 'home-page-wrapper content8-wrapper jxpqyj4jcol-editor_css',
  },
  page: { className: 'home-page content8 jxpqhcaiwoe-editor_css' },
  OverPack: { playScale: 0.1 },
  titleWrapper: {
    className: 'title-wrapper jxpsbfb9wkm-editor_css',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>各色体验，总有惊喜</p>
          </>
        ),
        className: 'title-h1 jxps9vgch2-editor_css',
      },
    ],
  },
  block: {
    className: 'content-wrapper',
    children: [
      {
        name: 'block2',
        md: 4,
        xs: 12,
        className: 'content8-block-wrapper jxpsepgc78q-editor_css',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children: 'https://pic.hereamazing.com/webpic/images/feiyishougong.jpg',
          },
          title: {
            className: 'content8-title jxpqqc6z2qq-editor_css',
            children: (
              <>
                <p>#戏剧表演#</p>
              </>
            ),
          },
          content: {
            className: 'content8-content jxpqqcxgyzb-editor_css',
            children: (
              <>
                {/* <p>搜「鱼眼体验」</p> */}
              </>
            ),
          },
        },
      },
      {
        name: 'block~jxpsjhwq9q',
        md: 4,
        xs: 12,
        className: 'content8-block-wrapper jxpsepgc78q-editor_css',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children: 'https://pic.hereamazing.com/webpic/images/shijingyanhuo.jpg',
          },
          title: {
            className: 'content8-title jxpqqc6z2qq-editor_css',
            children: (
              <>
                <p>#市井美食#</p>
              </>
            ),
          },
          content: {
            className: 'content8-content jxpqqcxgyzb-editor_css',
            children: (
              <>
                {/* <p>搜「鱼眼体验」</p> */}
              </>
            ),
          },
        },
      },
      {
        name: 'block~jxpsjimjoh7',
        md: 4,
        xs: 12,
        className: 'content8-block-wrapper jxpsepgc78q-editor_css',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children: 'https://pic.hereamazing.com/webpic/images/zhuoyoujuhui.jpg',
          },
          title: {
            className: 'content8-title jxpqqc6z2qq-editor_css',
            children: (
              <>
                <p>#桌游聚会#</p>
              </>
            ),
          },
          content: {
            className: 'content8-content jxpqqcxgyzb-editor_css',
            children: (
              <>
                {/* <p>搜「鱼眼体验」</p> */}
              </>
            ),
          },
        },
      },
      {
        name: 'block~jxpsjj6utt',
        md: 4,
        xs: 12,
        className: 'content8-block-wrapper jxpsepgc78q-editor_css',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children: 'https://pic.hereamazing.com/webpic/images/citywalk.jpg',
          },
          title: {
            className: 'content8-title jxpqqc6z2qq-editor_css',
            children: (
              <>
                <p>#CityWalk#</p>
              </>
            ),
          },
          content: {
            className: 'content8-content jxpqqcxgyzb-editor_css',
            children: (
              <>
                {/* <p>搜「鱼眼体验」</p> */}
              </>
            ),
          },
        },
      },
      {
        name: 'block~jxpsjjqj48i',
        md: 4,
        xs: 12,
        className: 'content8-block-wrapper jxpsepgc78q-editor_css',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children: 'https://pic.hereamazing.com/webpic/images/shougongfang.jpg',
          },
          title: {
            className: 'content8-title jxpqqc6z2qq-editor_css',
            children: (
              <>
                <p>#手工坊#</p>
              </>
            ),
          },
          content: {
            className: 'content8-content jxpqqcxgyzb-editor_css',
            children: (
              <>
                {/* <p>搜「鱼眼体验」</p> */}
              </>
            ),
          },
        },
      },
      {
        name: 'block~jxpsjkai8lr',
        md: 4,
        xs: 12,
        className: 'content8-block-wrapper jxpsepgc78q-editor_css',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children: 'https://pic.hereamazing.com/webpic/images/gongyixingdong.jpg',
          },
          title: {
            className: 'content8-title jxpqqc6z2qq-editor_css',
            children: (
              <>
                <p>#公益行动#</p>
              </>
            ),
          },
          content: {
            className: 'content8-content jxpqqcxgyzb-editor_css',
            children: (
              <>
                {/* <p>搜「鱼眼体验」</p> */}
              </>
            ),
          },
        },
      },
    ],
    gutter: 40,
  },
};
export const Footer20DataSource = {
  wrapper: {
    className: 'home-page-wrapper footer2-wrapper jxpt7c19n6-editor_css',
  },
  OverPack: {
    className: 'home-page footer2 jxpt6trvmf-editor_css',
    playScale: 0.05,
  },
  copyright: {
    className: 'copyright',
    children: [
      {
        name: 'group',
        children: (
          <>
            <p>·</p>
          </>
        ),
        className: 'copyright-group jxpt49d5qhn-editor_css',
      },
    ],
  },
  links: {
    className: 'links jxpt4undvjl-editor_css',
    children: [
      {
        name: 'weibo',
        href: 'https://weibo.com/u/7155128486',
        className: 'links-weibo jxpteagrq1i-editor_css',
        children: 'https://pic.hereamazing.com/webpic/images/weibo.png',
        icon: 'weibo',
        target: '_blank',
      },
      {
        name: 'zhihu',
        className: 'links-zhihu jxpteiy5mw-editor_css',
        children: 'https://pic.hereamazing.com/webpic/images/wechat_icon.png',
        type: 'dashed',
      },
    ],
  },
};
export const Footer00DataSource = {
  wrapper: {
    className: 'home-page-wrapper footer0-wrapper jxprn4m83k-editor_css',
  },
  OverPack: {
    className: 'home-page footer0 jxprenmq9y-editor_css',
    playScale: 0.05,
  },
  copyright: {
    className: 'copyright jxpres86xjf-editor_css',
    children: (
      <>
        <p>
          © 2019 成都去处网络科技有限公司 All Rights Reserved<br />
        </p>
        <span>鱼眼体验™&nbsp; &nbsp; &nbsp; &nbsp; <a href='http://beian.miit.gov.cn/' target='blank' style={{color:'#ddd'}}>蜀ICP备19016461号</a></span>
      </>
    ),
  },
};
