import React from 'react';
export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper jxsn7wu696-editor_css' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo jxsnhhexpc7-editor_css',
    children: 'https://pic.hereamazing.com/webpic/images/logo.svg',
  },
  Menu: {
    className: 'header0-menu jxpjefrz9io-editor_css',
    children: [
      {
        name: 'item0',
        a: { children: '首页', href: '/', className: 'jxsnawo7u16-editor_css' },
        className: 'jxsnclb8bh8-editor_css',
      },
      {
        name: 'item~jxsnsx2qijb',
        a: {
          children: '体验主',
          href: '/host',
          className: 'jxsnawo7u16-editor_css',
        },
        className: 'jxsnclb8bh8-editor_css',
      },
      {
        name: 'item~jxsnsy6hry',
        a: {
          children: '体验主管理后台',
          href: '/hostadmin',
          className: 'jxsnawo7u16-editor_css',
        },
        className: 'jxsnclb8bh8-editor_css',
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};
export const Banner00DataSource = {
  wrapper: { className: 'banner0 jxojc16h1z-editor1_css' },
  textWrapper: { className: 'banner0-text-wrapper jxojdzhi8bn-editor_css' },
  title: {
    className: 'banner0-title jxojbfnhvy-editor1_css',
    children: (
      <>
        <p>成为体验主，开启别样精彩，让生活散发光亮</p>
      </>
    ),
  },
  content: {
    className: 'banner0-content jxoje9sxbkp-editor_css',
    children: (
      <>
        <p></p>
      </>
    ),
  },
};

export const Banner20DataSource = {
  wrapper: { className: 'banner2' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        page: { className: 'home-page banner2-page jxr87fgsc1t-editor_css' },
        textWrapper: {
          className: 'banner2-text-wrapper jxr9e5vr9ms-editor_css',
        },
        bg: { className: 'bg bg0 jxr5pdqapzm-editor_css' },
        title: {
          className: 'banner2-title jxr9doeek2-editor_css',
          children: (
            <>
              <p>在这里, 人人都能分享,事事皆可体验</p>
            </>
          ),
        },
        content: {
          className: 'banner2-content jxr9elfhr1l-editor_css',
          children: (
            <>
              <p>你可以分享一切你认为值得分享的东西；</p>
              <p>比如你热爱的舞蹈、书法、音乐等艺术；</p>
              <p>或是你觉得好吃的美食，亦或是你每天以此谋生的职业和技能。</p>
            </>
          ),
        },
        button: {
          className: 'banner2-button jxrl2c51kuu-editor_css',
          children: 'Learn More',
        },
      },
    ],
    className: 'jxr9n65m5ah-editor_css',
  },
};
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper jxr875uwn3j-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper jxr8dm3ccye-editor_css',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>成为体验主，你将…</p>
          </>
        ),
        className: 'title-h1 jxr87986qxh-editor_css',
      },
    ],
  },
};
export const Banner10DataSource = {
  wrapper: { className: 'banner1 jxr5f1e5ihq-editor_css' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner1-text-wrapper jxr5kb10jj-editor_css',
        },
        bg: { className: 'bg bg0 jxr5elt9u3-editor_css' },
        title: {
          className: 'banner1-title jxr5k8b0bkp-editor_css',
          children: '拥有一个分享喜好、彰显自我的机会',
        },
        content: {
          className: 'banner1-content jxrl0ao38h-editor_css',
          children: '在这里分享你的兴趣爱好，让更多人与你共同体验别样的生活方式',
        },
        button: {
          className: 'banner1-button jxrl04uoi1l-editor_css',
          children: '',
        },
      },
      {
        name: 'elem1',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner1-text-wrapper jxr5ocubo9-editor_css',
        },
        bg: { className: 'bg bg1 jxr5p7gooh7-editor_css' },
        title: {
          className: 'banner1-title jxr5o50nwrk-editor_css',
          children: '遇见有趣、乐于尝鲜、热爱探索的同伴',
        },
        content: {
          className: 'banner1-content jxrl3hzzfak-editor_css',
          children: '通过开展体验，你可以结识到一群跟你有共同喜好的体验者，说不定你们还能成为志趣相投的伙伴',
        },
        button: {
          className: 'banner1-button jxrl3h8n5b-editor_css',
          children: '',
        },
      },
      {
        name: 'elem2',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner1-text-wrapper jxr5vi1eob6-editor_css',
        },
        bg: { className: 'bg bg1 jxr5rzcbcw6-editor_css' },
        title: {
          className: 'banner1-title jxr5uvmr6sl-editor_css',
          children: (
            <>
              <p>解锁赚取额外收入的新潮方式</p>
            </>
          ),
        },
        content: {
          className: 'banner1-content jxrl5j911k-editor_css',
          children: '你可以利用业余空闲时间开展体验，这样既能做喜爱的事情，还能获得一份额外的收入',
        },
        button: {
          className: 'banner1-button jxr5vq4kmej-editor_css',
          children: (
            <>
              <p>成为体验主</p>
            </>
          ),
          href:'https://www.hereamazing.com/hostadmin/register',
        },
      },
    ],
    type: ['across'],
    autoPlay: false,
    sync: false,
  },
};
export const Teams10DataSource = {
  wrapper: { className: 'home-page-wrapper teams1-wrapper' },
  page: { className: 'home-page teams1 jxr66gq8r6n-editor_css' },
  OverPack: { playScale: 0.3, className: '', always: true },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>Ta们已经加入</p>
          </>
        ),
        className: 'jxr7lfzt088-editor_css',
      },
    ],
  },
  block: {
    className: 'block-wrapper jxrlmywzjau-editor_css',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image jxr634vq4b-editor_css',
              children: 'https://pic.hereamazing.com/webpic/images/host-a.jpg',
            },
            {
              name: 'title',
              className: 'teams1-title jxr74ykrwd-editor_css',
              children: 'Nicole',
            },
            {
              name: 'content',
              className: 'teams1-job jxr75ry71n6-editor_css',
              children: '外企高管 | 瓶绘体验主',
            },
            {
              name: 'content1',
              className: 'teams1-content jxr765zr9zr-editor_css',
              children:
                '“曾经混迹职场18年，是化妆品行业跌宕起伏的见证者。在40岁的分水岭选择了急流勇退回家带娃，并做一件真正属于自己的事情——以一个红酒瓶开启的瓶绘世界。”',
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image jxr657zaala-editor_css',
              children: 'https://pic.hereamazing.com/webpic/images/host-b.jpg',
            },
            {
              name: 'title',
              className: 'teams1-title jxr6j5q889-editor_css',
              children: (
                <>
                  <p>小驹</p>
                </>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job jxr6ja9l7yo-editor_css',
              children: (
                <>
                  <p>戏曲/蜀绣艺人 | 蜀绣体验主</p>
                </>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content jxr6jf3ollf-editor_css',
              children:
                '“自幼便学习戏曲，曾在民中川剧团担任戏曲演员，曾多次获国内名奖，现在是一个戏曲工作室的创始人。和很多人相比，我是幸运的，因为在一开始就遇见了愿意为之付出一生的事业。”',
            },
          ],
        },
      },
      {
        name: 'block~jxr7aiszfrn',
        className: 'block jxr7drbocn-editor_css',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image jxr657zaala-editor_css',
              children: 'https://pic.hereamazing.com/webpic/images/host-c.jpg',
            },
            {
              name: 'title',
              className: 'teams1-title jxr6j5q889-editor_css',
              children: (
                <>
                  <p>船长</p>
                </>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job jxr6ja9l7yo-editor_css',
              children: (
                <>
                  <p>演讲培训师 |  即兴表演体验主</p>
                </>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content jxr6jf3ollf-editor_css',
              children:
                '“日常生活里，我是CAS教官、USAP比赛演讲评委培训师、张泉灵等明星的私人演讲顾问。在15年幸运地接触到戏剧后，被即兴表演充满未知惊喜的魅力深深吸引。于是利用业余时间，把它带给我的欢乐与灵感分享给更多人。”',
            },
          ],
        },
      },
    ],
  },
};
export const Feature40DataSource = {
  wrapper: {
    className: 'home-page-wrapper content6-wrapper jxr99fgtxyl-editor_css',
  },
  OverPack: {
    className: 'home-page content6 jxr7b2uye3u-editor_css',
    always: true,
  },
  textWrapper: { className: 'content6-text', xs: 24, md: 10 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>你需要做的很简单</p>
          </>
        ),
        className: 'title-h1 jxrjmbba50d-editor_css',
      },
      {
        name: 'content',
        className: 'title-content jxrkx0emjm-editor_css',
        children: '我们一直在努力为体验主赋能',
      },
    ],
  },
  img: {
    children: 'https://pic.hereamazing.com/webpic/images/itseasy.jpg',
    className: 'content6-img',
    xs: 24,
    md: 14,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children: 'https://pic.hereamazing.com/webpic/images/APP.png',
          className: 'content6-icon jxrkg42ee29-editor_css',
        },
        title: {
          className: 'content6-title jxrlcf942b8-editor_css',
          children: (
            <>
              <p>基础的服务条件</p>
            </>
          ),
        },
        content: {
          className: 'content6-content jxrlc13m8q7-editor_css',
          children:
            '具备基础的互联网（手机、APP）使用能力以及语言能力（目前仅要求普通话），以便于更好的为体验者提供服务。',
        },
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon',
          children: 'https://pic.hereamazing.com/webpic/images/house.png',
        },
        title: {
          className: 'content6-title jxrlcqfv8td-editor_css',
          children: (
            <>
              <p>合适的体验场所</p>
            </>
          ),
        },
        content: {
          className: 'content6-content jxrlce66clq-editor_css',
          children:
            '需要有合适的体验场所，可以是工作坊、家里、户外等，若是地点探访类活动，则需确保地点在活动过程中可控、可到达以及安全。',
        },
      },
      {
        name: 'block2',
        img: {
          className: 'content6-icon',
          children: 'https://pic.hereamazing.com/webpic/images/painting.svg',
        },
        title: {
          className: 'content6-title jxrlcx6uqfr-editor_css',
          children: (
            <>
              <p>特色、独一无二的活动</p>
            </>
          ),
        },
        content: {
          className: 'content6-content jxrldeq7hd-editor_css',
          children:
            '活动一定要有特色，可以是地域特色、某一领域的特色或个人特色，并因为体验主等因素具有不可复制的独特性。',
        },
      },
    ],
  },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'content',
        children: (
          <>
            <p>
              如果你也喜欢分享，欢迎加入鱼眼体验，任何疑问都可以与我们联系！
            </p>
          </>
        ),
        className: 'title-content jxrlfqbdzlr-editor_css',
      },
      {
        name: 'content2',
        children: (
          <>
            <p>Email：cnzhww@hereamazing.com</p>
          </>
        ),
        className: 'title-content jxrlfxif8vl-editor_css',
      },
    ],
  },
  button: {
    className: 'jxrk3peyqxh-editor_css',
    children: {
      a: {
        className: 'jxr7puwoyoj-editor_css',
        href: 'https://www.hereamazing.com/hostadmin/register',
        children: (
          <>
            <p>成为体验主</p>
          </>
        ),
        type: 'default',
        target: '_blank',
      },
    },
  },
};
export const Footer00DataSource = {
  wrapper: {
    className: 'home-page-wrapper footer0-wrapper jxsnu4746w-editor_css',
  },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright jxsntxpl72-editor_css',
    children: (
      <>
        <p>© 2019 成都去处网络科技有限公司 All Rights Reserved</p>
        <p>鱼眼体验™ &nbsp; &nbsp; &nbsp; &nbsp;<a href='http://beian.miit.gov.cn/' target='blank' style={{color:'#ddd'}}>蜀ICP备19016461号</a></p>
      </>
    ),
  },
};
// eslint-disable-next-line
function newFunction() {
  return false;
}

